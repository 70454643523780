import { MeshGeometry3D } from "./mesh-geometry"

export namespace CubeGeometry {
  export function create() {
    return Object.assign(new MeshGeometry3D(), {
      positions: {
        buffer: new Float32Array([-1, 1, 1,  -1, -1, -1,  -1, -1, 1,  -1, 1, -1,  // 0, 1, 2, 3
                                 -1, 1, -1,  1, -1, -1,  -1, -1, -1,  1, 1, -1,  // 4, 5, 6, 7
                                 1, 1, -1,  1, -1, 1,  1, -1, -1,  1, 1, 1, // 8, 9, 10, 11
                                 1, 1, 1,  -1, -1, 1,  1, -1, 1,  -1, 1, 1,  // 12, 13, 14, 15
                                 1, -1, 1,  -1, -1, -1,  1, -1, -1,  -1, -1, 1,  // 16, 17, 18, 19
                                 -1, 1, 1,  1, 1, -1,  -1, 1, -1,  1, 1, 1]) // 20, 21, 22, 23
      },
      indices: {
        buffer: new Uint8Array([0, 1, 2, 0, 3, 1, 4, 5, 6, 4, 7, 5, 8, 9, 10, 8, 11, 9, 12, 13, 14, 12, 15, 13, 16, 17, 18, 16, 19, 17, 20, 21, 22, 20, 23, 21])
      },
      normals: {
        buffer: new Float32Array([-1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, -1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0, 0, 1, 0])
      },
      uvs: [{
        buffer: new Float32Array([0.66666, 1, 0.33333, 0.75, 0.33333, 1, 0.66666, 0.75, 0.66666, 0.75, 0.33333, 0.5, 0.33333, 0.75, 0.66666, 0.5, 0.66666, 0.5, 0.33333, 0.25, 0.33333, 0.5, 0.66666, 0.25, 0.66666, 0.25, 0.33333, 0, 0.33333, 0.25, 0.66666, 0, 0.33333, 0.25, 0.125, 0.5, 0.33333, 0.5, 0.125, 0.25, 1.0, 0.25, 0.66666, 0.5, 1.0, 0.5, 0.66666, 0.25])
      }],
      tangents: {
        buffer: new Float32Array([0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, 1, 0, 0, 1, -1, 0, 0, 1, -1, 0, 0, 1, -1, 0, 0, 1, -1, 0, 0, 1])
      }
    })
  }
}